/* eslint-disable no-unreachable */
import "../../style.css";
import lod_ from "lodash";
import { CircularProgress } from "@mui/material";
import { createDictionarySkeleton } from "helpers/form";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import FormAction from "redux-react/actions/formAction";
import SettingsActions from "redux-react/actions/settingsActions";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { LittleForm } from "components/Custom/LittleForm";
import i18n from "i18n";
import GoogleConfiguration from "./specific/googleReview";
import MicrosoftConfiguration from "./specific/microsoft";
import MetaConfiguration from "./specific/meta";

/**
 * Step 1: Selected the profile type
 */
const Step3ConfigPart = ({
	validStep,
	onChange,
	commonPart,
	config,
	setConfigPart,
	channel,
	channelSubType = {},
	extraDatas,
	setExtraDatas
}) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [dictionary, setDictionary] = useState({});
	const [configSkeleton, setConfigSkeleton] = useState({});

	const [CMConfig, setCMConfig] = useState(undefined);

	useEffect(() => {
		setConfigSkeleton(config);
	}, [config]);

	useEffect(() => {
		const getConfigDictionarySuccess = (code, res) => {
			let dictionaries = res?.dictionaries ?? {};
			if (dictionaries[code]) {
				setDictionary(dictionaries[code].items);
				let buildedConfig = createDictionarySkeleton(dictionaries[code], config);

				setConfigSkeleton(buildedConfig);
				setConfigPart(buildedConfig);
			}
			setLoading(false);
			validStep();
		};

		const getConfigDictionary = code => {
			dispatch(
				SettingsActions.getDictionaries(
					{
						query: {
							codeDictionary: code
						},
						catalog: "channel"
					},
					res => {
						getConfigDictionarySuccess(code, res);
					}
				)
			);
		};

		if (channelSubType.codeDictionary) {
			getConfigDictionary(channelSubType.codeDictionary);
		} else {
			setLoading(false);
			validStep();
		}
	}, []);

	/**
	 * Get a specfic part depending on the channel type
	 * @returns
	 */
	const getConfigurationContent = () => {
		const subtypeCMConfig = {
			GOGR: "google",
			gmail: "google",
			MSFT: "microsoft",
			FB: "facebook",
			IS: "facebook",
			WA: "facebook"
		};

		const { subType } = channel;

		if (CMConfig === undefined) {
			setCMConfig(null);
			dispatch(
				SettingsActions.getCMConfig(subtypeCMConfig[subType], res => {
					setCMConfig(res.config);
				})
			);
		}
		if (loading || !CMConfig) {
			return (
				<MDBox
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={{
						height: "auto",
						width: "100%"
					}}
				>
					<CircularProgress color="info" />
				</MDBox>
			);
		}

		switch (subType) {
			// Google Review
			case "GOGR": {
				return (
					<GoogleConfiguration
						googleConfig={CMConfig}
						channelCode={commonPart.code}
						config={config}
						onChange={onChange}
						setConfigPart={setConfigPart}
						type="GOGR"
						googleType="mybusiness"
						dictionary={dictionary}
						configSkeleton={configSkeleton}
						extraDatas={extraDatas}
						setExtraDatas={setExtraDatas}
					/>
				);
			}
			// Gmail
			case "gmail": {
				return (
					<GoogleConfiguration
						googleConfig={CMConfig}
						channelCode={commonPart.code}
						config={config}
						onChange={onChange}
						setConfigPart={setConfigPart}
						type="gmail"
						googleType="gmail"
						dictionary={dictionary}
						configSkeleton={configSkeleton}
						extraDatas={extraDatas}
						setExtraDatas={setExtraDatas}
					/>
				);
			}
			// Microsoft mails
			case "MSFT": {
				return (
					<MicrosoftConfiguration
						microsoftConfig={CMConfig}
						channelCode={commonPart.code}
						config={config}
						onChange={onChange}
						setConfigPart={setConfigPart}
						type="office365"
						dictionary={dictionary}
						configSkeleton={configSkeleton}
						extraDatas={extraDatas}
						setExtraDatas={setExtraDatas}
					/>
				);
			}
			case "FB":
			case "IS":
			case "WA": {
				return (
					<MetaConfiguration
						metaConfig={CMConfig}
						channelCode={commonPart.code}
						config={config}
						onChange={onChange}
						setConfigPart={setConfigPart}
						type="office365"
						dictionary={dictionary}
						configSkeleton={configSkeleton}
						extraDatas={extraDatas}
						setExtraDatas={setExtraDatas}
					/>
				);
			}
			// Default
			default:
				return (
					<>
						{!loading && lod_.isEmpty(dictionary) && (
							<MDBox display="flex" justifyContent="center">
								<MDTypography variant="body2" sx={{ opacity: 0.7 }}>
									{i18n.t("CHANNEL.noConfigurationAvailable")}
								</MDTypography>
							</MDBox>
						)}

						{loading ? (
							<MDBox
								display="flex"
								alignItems="center"
								justifyContent="center"
								sx={{
									height: "auto",
									width: "100%"
								}}
							>
								<CircularProgress color="info" />
							</MDBox>
						) : (
							<LittleForm
								object={dictionary}
								metadatasSkeleton={configSkeleton}
								handleChange={(path, value) => {
									onChange(path, value);
								}}
							/>
						)}
					</>
				);
		}
	};

	return (
		<MDBox sx={{ height: "100%", width: "100%" }} display="flex" justifyContent="center">
			<MDBox sx={{ height: "100%", width: "60%", display: "flex", flexDirection: "column" }} pt={5}>
				<MDBox display="flex" alignItems="center" pb={2}>
					<MDBox
						sx={{
							height: "3rem",
							width: "3rem"
						}}
						mr={1}
					>
						<MDBox
							component="img"
							borderRadius="md"
							src={channel.logoURL}
							alt={channel.logoURL}
							sx={{
								width: "100%",
								height: "auto",
								objectFit: "contain"
							}}
						/>
					</MDBox>

					<MDTypography variant="h4">{`${i18n.t("CHANNEL.configureChannel")} ${channel.name ? channel.name : ""}`}</MDTypography>
				</MDBox>
				{getConfigurationContent()}
			</MDBox>
		</MDBox>
	);
};

export default Step3ConfigPart;
