/* eslint-disable no-debugger */
/* eslint-disable import/order */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable object-shorthand */
/* eslint-disable no-plusplus */

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import lod_ from "lodash";
import ChartsActions from "redux-react/actions/chartsActions";
import {
	Card,
	Chip,
	Dialog,
	Fab,
	Fade,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	Tab,
	Tabs,
	TextField,
	Tooltip
} from "@mui/material";

import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MDBadge from "components/Basics/MDBadge";
import { LittleForm } from "components/Custom/LittleForm";
import ConditionBox from "./ConditionBox";
import { display } from "redux-react/reducers/snackBarReducer";
import { Save, Delete, Add, Edit } from "@mui/icons-material";
import i18n from "i18n";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import RulesAction from "redux-react/actions/rulesActions";
import {
	setEnableRouting,
	changeRouteHandle,
	setDisableRouting
} from "redux-react/reducers/applicationReducer";
import { useNavigate } from "react-router-dom";
import { selectUser } from "redux-react/reducers/userReducers";
import { createDictionarySkeleton } from "helpers/form";

/**
 * Component to display rule from "ruleConfig", and edit them
 * @param {*} props
 * @returns
 */
export default function RuleComponent(props) {
	const options = props.options ?? {};
	const navigate = useNavigate();
	const [ruleCopy, setRuleCopy] = useState(lod_.cloneDeep(props.rule));
	const [rule, setRule] = useState(lod_.cloneDeep(props.rule));
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);
	const user = useSelector(selectUser);
	const [canSaveChanges, setCanSaveChanges] = useState(false);

	const [getDico, setDictionary] = useState({});
	const [dicoForLogic, setDicoForLogic] = useState(props.dictionary);

	const [isEditing, setIsEditing] = useState(false);

	const [disableDrag, setDisableDrag] = useState(false);

	const handleDoubleClick = () => {
		setIsEditing(true);
	};

	const handleBlur = () => {
		setIsEditing(false);
	};

	useEffect(() => {
		const onSuccess = res => {
			setDictionary(res.dictionary);
		};
		dispatch(RulesAction.getDictionary(profile.assistantID, onSuccess));
	}, []);

	/* Update condition's name by it's index */
	const updateLogic = ({ logic, logicCodes }, index) => {
		setRule(prevState => {
			prevState.conditions[index].logic = logic;
			prevState.conditions[index].logicCodes = logicCodes;
			return {
				...prevState,
				conditions: prevState.conditions
			};
		});
	};
	/* Update condition's name by it's index */
	const updateName = (name, key) => {
		setRule(prevState => {
			let conditions = lod_.clone(prevState.conditions);
			conditions[key].name = name;
			return {
				...prevState,
				conditions: conditions
			};
		});
	};
	/* Update condition's active by it's index */
	const updateActive = (active, key) => {
		setRule(prevState => {
			let conditions = lod_.clone(prevState.conditions);
			conditions[key].active = active;
			return {
				...prevState,
				conditions: conditions
			};
		});
	};
	/* Update condition's output by it's index */
	const handleSaveOutput = (output, key) => {
		setRule(prevState => {
			let conditions = lod_.clone(prevState.conditions);
			conditions[key].output = output;
			return {
				...prevState,
				conditions: conditions
			};
		});
	};
	/* Add new condition */
	const addNewCondition = () => {
		// Create empty condition
		let emptyCond = {
			logic: `{ "and": [ ] }`,
			name: `Condition #${rule.conditions.length + 1}`,
			order: rule.conditions.length,
			output: {},
			active: true
		};
		// Add it to the rule
		setRule(prevState => {
			return {
				...prevState,
				conditions: [...prevState.conditions, emptyCond]
			};
		});
	};
	/* Remove condition by it's index */
	const removeCondition = key => {
		setRule(prevState => {
			let conditions = lod_.clone(prevState.conditions);
			conditions.splice(key, 1);
			return {
				...prevState,
				conditions: conditions
			};
		});
	};
	/* Save updated rule */
	const handleSave = () => {
		dispatch(
			ChartsActions.updateRule(profile.assistantID, props.rule.code, rule, res => {
				dispatch(
					display({
						message: i18n.t("SETTINGS.RULES.CALL.successUpdate"),
						type: "success"
					})
				);
			})
		);
		setRuleCopy(lod_.cloneDeep(rule));
	};
	/* Handle drag element */
	const handleElementDrag = ({ destination, source }) => {
		// -> Set "source" element in top of "destination" element
		let sourceIndex = source.index;
		let destinationIndex = destination.index;
		setRule(prevState => {
			let conditions = lod_.clone(prevState.conditions);
			let tempItem = conditions[sourceIndex];

			conditions.splice(sourceIndex, 1);
			conditions.splice(destinationIndex, 0, tempItem);

			conditions.map((cond, index) => {
				cond.order = index;
			});

			return {
				...prevState,
				conditions: conditions
			};
		});
	};

	const [tab, setTab] = useState(0);

	const [defaultOutputSkeleton, setDefaultOutputSkeleton] = useState(
		createDictionarySkeleton(props.outputDictionary, rule.output?.fallback || {})
	);

	const handleChange = (path, value) => {
		let clone = lod_.cloneDeep(defaultOutputSkeleton);
		lod_.set(clone, path, value);
		setDefaultOutputSkeleton(clone);
		setRule(prevState => {
			return {
				...prevState,
				output: {
					...prevState.output,
					fallback: clone
				}
			};
		});
	};

	useEffect(() => {
		if (!lod_.isEqual(rule, ruleCopy)) {
			setCanSaveChanges(true);
			dispatch(setDisableRouting());
		} else {
			setCanSaveChanges(false);
			dispatch(setEnableRouting());
		}
	}, [rule, ruleCopy]);

	const deleteItem = path => {
		let copySelectedD = lod_.cloneDeep(rule);
		copySelectedD.inputDictionaries = copySelectedD.inputDictionaries.filter(
			element => element !== path
		);
		setRule(copySelectedD);

		let copyDico = lod_.cloneDeep(dicoForLogic);
		delete copyDico[path];

		setDicoForLogic(copyDico);
	};

	const addItem = item => {
		let copySelectedD = lod_.cloneDeep(rule);
		copySelectedD.inputDictionaries.push(item);
		setRule(copySelectedD);

		let newDico = lod_.get(getDico, item) || {};
		let copyDico = lod_.cloneDeep(dicoForLogic);
		copyDico[item] = newDico;

		setDicoForLogic(copyDico);
	};

	/** IF NEED TO HAVE LABEL IN TOOLTIP */
	// const getLabelOfItem = path => {
	// 	return lod_.get(getDico, path)?.label?.fr || "";
	// };

	const { changeRoute, disableRouting } = useSelector(state => state.application);
	const [unsavedDialog, setUnsavedDialog] = useState(false);

	useEffect(() => {
		if (!disableRouting || changeRoute === props.route.route) {
			return;
		}
		setUnsavedDialog(true);
	}, [changeRoute]);

	const canEditOption = () => {
		if (lod_.isEmpty(options)) {
			return false;
		}

		if (!lod_.isEmpty(options)) {
			if (!user.system && rule.system) {
				return false;
			}
		}

		return true;
	};

	return (
		<>
			<Card>
				<MDBox p={3}>
					{/* Top row : name / actions buttons */}
					<MDBox display="flex" alignItems="center" justifyContent="space-between" mb={4}>
						{/* Rule name */}
						<MDBox display="flex" alignItems="center" style={{ width: "100%" }}>
							<MDBox onDoubleClick={handleDoubleClick} style={{ width: "100%" }} display="flex">
								{isEditing ? (
									<>
										<IconButton color="success" onClick={() => setIsEditing(false)}>
											<Icon>check</Icon>
										</IconButton>
										<TextField
											fullWidth
											value={rule.name}
											onChange={e =>
												setRule(prevState => ({
													...prevState,
													name: e.target.value
												}))
											}
											inputProps={{
												style: {
													fontSize: "1.5rem",
													color: "#344767",
													fontWeight: "700",
													letterSpacing: "0.00735em",
													opacity: "1",
													textTransform: "none",
													verticalAlign: "unset"
												}
											}}
											onBlur={handleBlur}
										/>
									</>
								) : (
									<MDBox display="flex" alignItems="center">
										<IconButton onClick={handleDoubleClick}>
											<Icon>edit</Icon>
										</IconButton>
										<MDTypography variant="h2">{rule.name}</MDTypography>
									</MDBox>
								)}
							</MDBox>
						</MDBox>
						{/* Rule tabs */}
						<MDBox mr={2}>
							{canEditOption() && (
								<MDBox ml={2}>
									<Tabs
										orientation="horizontal"
										value={tab}
										onChange={(e, v) => {
											setTab(v);
										}}
									>
										<Tab
											label="Configuration"
											icon={
												<Icon fontSize="small" sx={{ mt: -0.25 }}>
													looks_one
												</Icon>
											}
										/>
										<Tab
											label="Options"
											icon={
												<Icon fontSize="small" sx={{ mt: -0.25 }}>
													settings
												</Icon>
											}
										/>
									</Tabs>
								</MDBox>
							)}
						</MDBox>
						{/* Rule informations */}
						<MDBox display="flex" alignItems="center">
							{options.activable && (
								<MDBadge
									color={Boolean(rule.active) ? "success" : "error"}
									badgeContent={Boolean(rule.active) ? "Actif" : "Inactif"}
								/>
							)}
							<MDBox ml={2} display="flex" alignItems="center">
								<Tooltip
									placement="top"
									title={rule.description}
									componentsProps={{
										tooltip: {
											sx: {
												whiteSpace: "pre-line !important",
												textAlign: "left !important"
											}
										}
									}}
								>
									<Icon fontSize="medium">info_outlined</Icon>
								</Tooltip>
							</MDBox>
						</MDBox>
					</MDBox>
					{/* Content */}
					{tab === 0 && (
						<MDBox>
							<DragDropContext onDragEnd={handleElementDrag}>
								<Droppable droppableId="dnd-list" direction="vertical">
									{provided => (
										<MDBox {...provided.droppableProps} ref={provided.innerRef}>
											{rule.conditions.map((cond, key) => {
												let condKey = cond.name.toLowerCase().replaceAll(" ", "_");

												return (
													<Draggable
														key={condKey}
														index={key}
														draggableId={condKey}
														isDragDisabled={disableDrag}
													>
														{provided => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
															>
																<ConditionBox
																	setDisableDrag={setDisableDrag}
																	cond={cond}
																	rule={rule}
																	dictionary={dicoForLogic}
																	updateLogic={logic => {
																		updateLogic(logic, key);
																	}}
																	listAPIs={props.listAPIs}
																	updateName={name => updateName(name, key)}
																	updateActive={active => updateActive(active, key)}
																	outputDictionary={props.outputDictionary}
																	handleSaveOutput={output => {
																		handleSaveOutput(output, key);
																	}}
																	removeCondition={() => {
																		removeCondition(key);
																	}}
																/>
															</div>
														)}
													</Draggable>
												);
											})}
											{provided.placeholder}
											<MDBox mt={3}>
												<MDButton
													fontSize="medium"
													color="light"
													variant="gradient"
													onClick={addNewCondition}
												>
													<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.RULES.addCondition")}
												</MDButton>
											</MDBox>
										</MDBox>
									)}
								</Droppable>
							</DragDropContext>
						</MDBox>
					)}
					{tab === 1 && (
						<MDBox>
							<MDBox>
								<MDTypography variant="h4">{i18n.t("SETTINGS.RULES.options")}</MDTypography>

								{lod_.isEmpty(options) && (
									<MDTypography variant="body2" color="textSecondary">
										{i18n.t("SETTINGS.RULES.noOptionsAvailable")}
									</MDTypography>
								)}

								{options.activable && (
									<>
										<MDBox
											mt={1}
											display="flex"
											justifyContent="space-between"
											alignItems="center"
											className="customSwitchContainer"
											onClick={() => {
												setRule(prevState => ({ ...prevState, active: !Boolean(prevState.active) }));
											}}
										>
											{i18n.t("SETTINGS.RULES.active")}
											<Switch checked={Boolean(rule.active)} />
										</MDBox>

										<MDBox mt={1}>
											<FormControl fullWidth>
												<InputLabel id="select-label">{i18n.t("SETTINGS.RULES.mode")}</InputLabel>
												<Select
													labelId="select-label"
													id="select"
													label={i18n.t("SETTINGS.RULES.mode")}
													value={rule.mode || null}
													onChange={e =>
														setRule(prevState => ({ ...prevState, mode: e.target.value }))
													}
												>
													{["first", "any"].map((option, index) => (
														<MenuItem key={index} value={option}>
															{option}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</MDBox>

										<MDTypography mt={1} variant="body2" color="textSecondary">
											{i18n.t("SETTINGS.RULES.inputDictionary")}
										</MDTypography>

										<MDBox mt={1}>
											{!lod_.isEmpty(getDico) ? (
												Object.keys(getDico)
													.sort((a, b) => getDico[a]?.label?.fr.localeCompare(getDico[b]?.label?.fr))
													.map((child, key) => {
														return (
															<Chip
																style={{
																	textTransform: "initial",
																	cursor: "pointer",
																	marginRight: "1%",
																	marginBottom: "1%"
																}}
																color={
																	rule?.inputDictionaries.find(objet => objet === child)
																		? "info"
																		: "default"
																}
																label={getDico[child]?.label?.fr}
																onClick={() =>
																	rule?.inputDictionaries.find(objet => objet === child)
																		? deleteItem(child)
																		: addItem(child)
																}
																icon={
																	rule?.inputDictionaries.find(objet => objet === child) ? (
																		<Delete />
																	) : (
																		<Add />
																	)
																}
															/>
														);
													})
											) : (
												<div>{i18n.t("SETTINGS.RULES.emptyInput")}</div>
											)}
										</MDBox>
									</>
								)}
								{options.fallbackOutput && (
									<MDBox mt={3}>
										<MDTypography variant="h6">
											{i18n.t("SETTINGS.RULES.fallbackOutput")}
										</MDTypography>
										<MDBox mt={2} bgColor="white" borderRadius="lg">
											<LittleForm
												object={props.outputDictionary}
												listAPIs={props.listAPIs}
												metadatasSkeleton={defaultOutputSkeleton}
												handleChange={handleChange}
											/>
										</MDBox>
									</MDBox>
								)}
							</MDBox>
						</MDBox>
					)}
				</MDBox>
			</Card>
			<Fade in={canSaveChanges}>
				<div
					style={{
						position: "fixed",
						bottom: 20,
						right: 20,
						zIndex: 1000
					}}
				>
					<Fab variant="extended" color="info" onClick={handleSave}>
						<MDTypography variant="h6" color="light">
							{i18n.t("SETTINGS.RULES.detectedModifications")}
						</MDTypography>
						<MDBox
							bgColor="light"
							p={1}
							borderRadius="lg"
							fontWeight="bold"
							display="flex"
							alignItems="center"
							ml={2}
						>
							<Save sx={{ mr: 1 }} />
							&nbsp;{i18n.t("SETTINGS.save")}
						</MDBox>
					</Fab>
				</div>
			</Fade>

			{/* Unsaved dialog */}
			{unsavedDialog && (
				<Dialog
					fullWidth
					maxWidth="md"
					open={unsavedDialog}
					onClose={() => {
						setUnsavedDialog(false);
						dispatch(setEnableRouting());
					}}
				>
					<MDBox p={3}>
						<MDTypography variant="h4">{i18n.t("SETTINGS.dialogSave.title")}</MDTypography>
						<MDBox mt={2}>
							<MDTypography variant="body2">{i18n.t("SETTINGS.dialogSave.message")}</MDTypography>
						</MDBox>
						<MDBox mt={2} display="flex" justifyContent="space-between">
							<MDButton
								onClick={() => {
									setUnsavedDialog(false);
									// Reset route to actual one
									dispatch(changeRouteHandle(props.route.route));
								}}
							>
								{i18n.t("SETTINGS.cancel")}
							</MDButton>

							<MDBox display="flex">
								<MDBox mr={1}>
									<MDButton
										variant="gradient"
										color="error"
										onClick={() => {
											// Close dialog
											setUnsavedDialog(false);
											// Reenable routing
											dispatch(setEnableRouting());
											// Go to the new route (where user wanted to go) and dont save settings
											navigate(changeRoute);
										}}
									>
										<Icon>delete</Icon>&nbsp;{i18n.t("SETTINGS.dialogSave.dontSave")}
									</MDButton>
								</MDBox>

								<MDButton
									variant="gradient"
									color="info"
									onClick={() => {
										// Close dialog
										setUnsavedDialog(false);
										// Save settings
										handleSave();
										// Reenable routing
										dispatch(setEnableRouting());
										// Go to the new route (where user wanted to go)
										navigate(changeRoute);
									}}
								>
									<Icon>save</Icon>&nbsp;{i18n.t("SETTINGS.dialogSave.save")}
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				</Dialog>
			)}
			<MDBox m={7}></MDBox>
		</>
	);
}
