import { WhatsApp } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { socket } from "redux-react/middleware/ws";
import MDBox from "components/Basics/MDBox";
import { LittleForm } from "components/Custom/LittleForm";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import { t } from "i18next";

function getFacebookLoginUrl(
	facebookLoginHost,
	facebookLoginVersion,
	APP_ID,
	REDIRECT_URI,
	scope,
	state
) {
	const params = {
		app_id: APP_ID,
		redirect_uri: REDIRECT_URI,
		scope,
		response_type: "code",
		state: JSON.stringify(state)
	};

	const loginRoute = `https://${facebookLoginHost}/${facebookLoginVersion}/dialog/oauth`;

	return `${loginRoute}?${new URLSearchParams(params).toString()}`;
}

const MetaConfiguration = ({
	metaConfig,
	channelCode,
	config,
	onChange,
	setConfigPart,
	type,
	dictionary,
	configSkeleton
}) => {
	const user = useSelector(state => state.user);
	const profile = useSelector(selectCurrentProfile);

	const metaTokenLoaded = config?.PAGE_ACCESS_TOKEN;

	const state = {
		ws: user.userID,
		action: "create",
		channelCode,
		assistantID: profile.assistantID
	};

	const { APP_ID, REDIRECT_URI, api, types } = metaConfig || {};
	const { facebookLogin } = api || {};
	const { host, version } = facebookLogin || {};

	const SCOPE = types.WA.scope;

	const url = getFacebookLoginUrl(host, version, APP_ID, REDIRECT_URI, SCOPE, state);

	const openAuthorizationIframe = () => {
		window.open(url, "_blank", "width=600,height=800").focus();
	};

	/**
	 * Result function of websocket event completeConfigChannelCreation
	 * Got when user creates a channel
	 * @param {*} { channel }
	 * @returns
	 */
	const completeConfigChannelCreation = ({ channel }) => {
		if (channel.code !== channelCode) {
			return;
		}

		let channelConfig = channel.config;

		// V1 we prefil with the first account and first whatsapp number
		const firstWhatsappBusinessAccounts =
			channelConfig?.businesses?.[0]?.whatsappBusinessAccounts?.[0] || {};
		const apiKey = firstWhatsappBusinessAccounts?.id;
		const whatsappPhoneNumberID = firstWhatsappBusinessAccounts?.phoneNumbers?.[0]?.id;

		const update = {
			PAGE_ACCESS_TOKEN: channelConfig.PAGE_ACCESS_TOKEN,
			businesses: channelConfig?.businesses // Store all loaded info for debug
		};

		if (apiKey) {
			update.apiKey = apiKey;
			// Make sure to clean whatsappPhoneNumberID if we change the API key
			if (config.apiKey !== apiKey) {
				update.whatsappPhoneNumberID = "";
			}
		}
		if (whatsappPhoneNumberID) {
			update.whatsappPhoneNumberID = whatsappPhoneNumberID;
		}

		setConfigPart(prev => {
			// Update only the page access token
			return {
				...prev,
				...update
			};
		});
	};

	useEffect(() => {
		// Create socket listeners
		socket.on("completeConfigChannelCreation", completeConfigChannelCreation);

		return () => {
			socket.off("completeConfigChannelCreation", completeConfigChannelCreation);
		};
	}, []);

	return (
		<MDBox sx={{ mt: 4, flex: 1 }}>
			{/*
			 * When user is not connected to meta
			 */}

			<MDBox
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				style={{
					height: metaTokenLoaded ? "20%" : "100%"
				}}
			>
				<MDTypography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
					{metaTokenLoaded ? t("CHANNEL.META.connected") : t("CHANNEL.META.connectToMeta")}
				</MDTypography>
				<MDButton color="info" onClick={openAuthorizationIframe}>
					<WhatsApp />
					<MDBox sx={{ ml: 2 }} color="white">
						{metaTokenLoaded ? t("CHANNEL.META.refreshAccountAccess") : t("CHANNEL.META.connect")}
					</MDBox>
				</MDButton>
			</MDBox>

			{/*
			 * Display configuration
			 */}
			{metaTokenLoaded && (
				<MDBox>
					<LittleForm
						object={dictionary}
						metadatasSkeleton={configSkeleton}
						handleChange={(path, value) => {
							onChange(path, value);
						}}
					/>
				</MDBox>
			)}
		</MDBox>
	);
};

export default MetaConfiguration;
