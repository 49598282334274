/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable object-shorthand */
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Tooltip
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";
import { useEffect, useState } from "react";
import HTMLEditor from "components/Custom/HTMLEditor";
import ReviewType from "./Types/ReviewType";
import TicketType from "./Types/TicketType";

/**
 * Language input component
 * @param {*} param0
 * @returns
 */
const LanguageInput = ({
	code,
	language,
	languages,
	alternative,
	alternativeIndex,
	channelType,
	dictionary,
	rightMenuPannel,
	setRightMenuPannel,
	onChangePath,
	exitDialogOpen,
	setExitDialogOpen,
	setOpenEditor,
	addNewAttachment
}) => {
	// Get the language alternative content object (text, attachments, subject, etc...)
	const languageAlternativeContent = alternative[language.language];
	const value = languageAlternativeContent?.text || "";

	const [attachments, setAttachments] = useState(languageAlternativeContent?.attachments || []);

	/**
	 * Depending on the channel type, return the right editor component
	 * @returns
	 */
	const getEditorComponent = () => {
		switch (channelType) {
			case "review":
				// Review type editor
				return (
					<ReviewType
						alternativeIndex={alternativeIndex}
						value={value}
						dictionary={dictionary}
						setRightMenuPannel={setRightMenuPannel}
						language={language}
						languages={languages}
						onChangePath={onChangePath}
					/>
				);
			case "ticket":
				// Ticket type editor
				return (
					<TicketType
						code={code}
						value={value}
						dictionary={dictionary}
						alternativeIndex={alternativeIndex}
						languageAlternativeContent={languageAlternativeContent}
						attachments={attachments}
						setAttachments={setAttachments}
						onChangePath={onChangePath}
						language={language}
						setOpenEditor={setOpenEditor}
						addNewAttachment={addNewAttachment}
					/>
				);
			default:
				// Default editor (none)
				return null;
		}
	};

	/**
	 * Get the AI generation icon color
	 * @returns string
	 */
	const getIconAIGenerationColor = () => {
		if (
			rightMenuPannel.open &&
			rightMenuPannel.alternative === alternativeIndex &&
			rightMenuPannel.language === language.language
		) {
			return "info";
		}
		return "default";
	};

	/**
	 * Reload the attachments list when the alternative index change
	 */
	useEffect(() => {
		setAttachments(languageAlternativeContent?.attachments || []);
	}, [alternativeIndex]);

	return (
		<MDBox
			sx={{ mb: 1 }}
			display="flex"
			bgColor="white"
			p={1}
			borderRadius="md"
			style={{
				border: "1px solid rgb(232, 232, 238)"
			}}
			flexDirection="column"
		>
			{/* Top bar */}
			<MDBox display="flex" alignItems="center" justifyContent="space-between">
				<MDBox display="flex" alignItems="center">
					{/* Logo */}
					{language.logoURL && (
						<img
							src={language.logoURL}
							alt={language.language}
							style={{
								width: "20px",
								height: "20px",
								borderRadius: "50%",
								marginRight: "10px"
							}}
						/>
					)}
					{/* Name */}
					<MDTypography variant="body2">{language.name}</MDTypography>
				</MDBox>
				{/* AI Generation */}
				<MDBox>
					<Tooltip placement="top" title={t("COMPONENT.ANSWERTYPE.generateAnAnswer")}>
						<IconButton
							color={getIconAIGenerationColor()}
							size="small"
							onClick={() => {
								setRightMenuPannel({
									open: true,
									alternative: alternativeIndex,
									language: language.language,
									languages: languages
								});
							}}
						>
							<Icon>emoji_objects</Icon>
						</IconButton>
					</Tooltip>
				</MDBox>
			</MDBox>

			{/* Editor, depending on answer type */}
			<MDBox sx={{ mt: 1 }}>{getEditorComponent()}</MDBox>

			{/* Exit dialog confirmation */}
			<Dialog open={exitDialogOpen} onClose={() => setExitDialogOpen(false)} fullWidth maxWidth="md">
				<DialogTitle>{t("EDITOR.leaveEditor")}</DialogTitle>
				<DialogContent>{t("EDITOR.leaveEditorMessage")}</DialogContent>
				<DialogActions>
					<MDButton variant="outlined" color="info" onClick={() => setExitDialogOpen(false)}>
						{t("SETTINGS.cancel")}
					</MDButton>
					<MDButton
						variant="contained"
						color="error"
						onClick={() => {
							setExitDialogOpen(false);
							setOpenEditor(prev => {
								return {
									open: false,
									html: ""
								};
							});
						}}
					>
						{t("SETTINGS.confirm")}
					</MDButton>
				</DialogActions>
			</Dialog>
		</MDBox>
	);
};

/**
 * Alternative component
 * @param {*} param0
 * @returns JSX
 */
export default function Alternative({
	code,
	alternative,
	languages,
	dictionary,
	channelType,
	alternativeIndex,
	onChangePath,
	rightMenuPannel,
	setRightMenuPannel,
	addNewAttachment
}) {
	const [exitDialogOpen, setExitDialogOpen] = useState(false);
	const [openEditor, setOpenEditor] = useState({
		open: false,
		html: ""
	});

	/**
	 * Handle the editor save
	 * @param {String} html The HTML content
	 * @param {Boolean} editMode If the editor is in edit mode
	 * @param {String} language Language
	 */
	const htmlEditorSave = (html, editMode, language) => {
		onChangePath(alternativeIndex, language, "text", html);

		setOpenEditor({
			open: false,
			html: ""
		});
	};

	const VALID_DICTIONARIES_TYPES = ["context", "contact", "profile"];

	const getValidDictionaries = () => {
		const validDictionaries = {};

		VALID_DICTIONARIES_TYPES.forEach(type => {
			if (dictionary[type]) {
				validDictionaries[type] = dictionary[type];
			}
		});

		return validDictionaries;
	};

	/**
	 * Main component
	 */
	return (
		<MDBox
			display="flex"
			flexDirection="row"
			style={{
				height: "100%",
				backgroundColor: "rgb(251, 251, 252)"
			}}
		>
			<MDBox
				flex="1"
				p={1}
				style={{
					position: "relative",
					width: "100%"
				}}
			>
				{languages.map((LANGUAGE, index) => {
					return (
						<LanguageInput
							code={code}
							key={index}
							language={LANGUAGE}
							dictionary={dictionary}
							channelType={channelType}
							alternative={alternative}
							alternativeIndex={alternativeIndex}
							rightMenuPannel={rightMenuPannel}
							setRightMenuPannel={setRightMenuPannel}
							languages={languages}
							onChangePath={(path, value) => {
								onChangePath(alternativeIndex, LANGUAGE.language, path, value);
							}}
							exitDialogOpen={exitDialogOpen}
							setExitDialogOpen={setExitDialogOpen}
							setOpenEditor={setOpenEditor}
							addNewAttachment={addNewAttachment}
						/>
					);
				})}
				{/* Editor => must be include only once in this component, that why it's not in the <LanguageInput /> */}
				<HTMLEditor
					simpleEditor
					open={openEditor.open}
					html={openEditor.html}
					id={openEditor.id}
					onClose={html => {
						setExitDialogOpen(true);
					}}
					onSave={htmlEditorSave}
					variablesDictionary={getValidDictionaries()}
					s3={{
						extra: {
							answerItemID: code
						},
						context: "answerItem"
					}}
				/>
			</MDBox>
		</MDBox>
	);
}
